import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseListComponent } from '../base-list/base-list.component';
import {
  AvatarAssetReference,
  CollectionScreenStore,
} from '../../collection-screen.state';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InputTextComponent } from '../../../components/inputs/input-text/input-text.component';
import { AvatarListRowComponent } from '../avatar-list-row/avatar-list-row.component';
import { InputDropdownComponent } from '../../../components/inputs/input-dropdown/input-dropdown.component';

export type AvatarGroup = {
  displayName: string;
  isValidGroup: boolean;
  references: AvatarAssetReference[];
};

@Component({
  selector: 'app-avatar-group-list',
  standalone: true,
  imports: [
    AvatarListRowComponent,
    CommonModule,
    DragDropModule,
    InputTextComponent,
    InputDropdownComponent,
  ],
  templateUrl: './avatar-group-list.component.html',
  styleUrls: [
    './avatar-group-list.component.scss',
    '../base-list/base-list.scss',
  ],
})
export class AvatarGroupListComponent
  extends BaseListComponent<AvatarGroup>
  implements OnInit, OnDestroy
{
  disableMove = undefined;
  groupedAvatars: AvatarGroup[] = [];
  activeGroup: string | undefined;
  dataSusbcription: any = undefined;
  storeSubscription: any = undefined;
  avatarCount = 0;
  ngOnInit(): void {
    this.storeSubscription =
      this.createCollectionStore.avatarActiveGroup$.subscribe((value) => {
        this.activeGroup = value;
      });
    this.createCollectionStore.setAvatarActiveGroup(undefined);
    this.dataSusbcription = this.data$.subscribe((data) => {
      this.groupedAvatars = data;
      this.avatarCount = this.groupedAvatars.reduce(
        (a, b) => a + b.references.length,
        0,
      );
    });
  }
  ngOnDestroy(): void {
    if (this.dataSusbcription) {
      this.dataSusbcription.unsubscribe();
      this.dataSusbcription = undefined;
    }
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
      this.storeSubscription = undefined;
    }
  }
  onGroupGenderChanged(group: string, gender: 'male' | 'female') {
    this.groupedAvatars
      .find((g) => g.displayName === group)
      ?.references?.forEach((a) => {
        if (a.additionalInformation === undefined) {
          a.additionalInformation = {
            heightInCm: 160,
            displayName: 'init',
            gender: 'female',
          };
        }
        if (gender === 'male' || gender === 'female') {
          a.additionalInformation.gender = gender;
        }
      });
  }
  heightValidation(value: string) {
    return Number.isFinite(+value);
  }
  onGroupHeightChanged(
    group: string,
    $event: { value: string; valid: boolean },
  ) {
    if ($event.valid) {
      this.groupedAvatars
        .find((g) => g.displayName === group)
        ?.references?.forEach((a) => {
          if (a.additionalInformation === undefined) {
            a.additionalInformation = {
              heightInCm: 160,
              displayName: 'init',
              gender: 'female',
            };
          }
          if ($event.value !== '') {
            a.additionalInformation.heightInCm = Number.parseInt($event.value);
          }
        });
    }
  }
  onDelete(group: string) {
    if (confirm('Are you sure you want to delete this group?')) {
      this.createCollectionStore.removeAvatarGroup(group);
    }
  }
  onGroupChanged(
    currentGroup: string,
    $event: {
      newGroup: string;
      toBeMoved: AvatarAssetReference;
    },
  ) {
    //add a new group when the plus symbol is clicked on the dropdown
    if ($event.newGroup === '+') {
      $event.toBeMoved.belongsTo = '!new group!';
      $event.toBeMoved.additionalInformation = {
        heightInCm: 160,
        displayName: 'init',
        gender: 'female',
      };
      this.groupedAvatars.push({
        displayName: '!new group!',
        isValidGroup: true,
        references: [$event.toBeMoved],
      });
      this.groupedAvatars.find(
        (g) => g.displayName === currentGroup,
      ).references = this.groupedAvatars
        .find((g) => g.displayName === currentGroup)
        .references.filter((r) => r.id !== $event.toBeMoved.id);
      this.groupedAvatars = this.groupedAvatars.filter(
        (g) => g.references.length > 0,
      );
      this.updateStoreAvatars();
      return;
    }
    if ($event.toBeMoved.belongsTo === undefined) {
      //ungrouped avatar
      const refs = this.groupedAvatars.find(
        (g) => g.displayName === $event.newGroup,
      ).references;
      $event.toBeMoved.additionalInformation = refs[0].additionalInformation;
      $event.toBeMoved.belongsTo = $event.newGroup;
      if (refs.some((r) => r.id !== $event.toBeMoved.id)) {
        refs.push($event.toBeMoved);
      }
      this.groupedAvatars = this.groupedAvatars.filter(
        (g) =>
          !(
            g.displayName === $event.toBeMoved.name && g.isValidGroup === false
          ),
      );
    } else {
      //already in another group. needs to be removed from the old group and added at the end of the new group
      this.groupedAvatars.find(
        (g) => g.displayName === $event.toBeMoved.belongsTo,
      ).references = this.groupedAvatars
        .find((g) => g.displayName === $event.toBeMoved.belongsTo)
        ?.references.filter((r) => r.id !== $event.toBeMoved.id);
      $event.toBeMoved.belongsTo = $event.newGroup;
      //take the additional information from the first reference and set it for the new avatar
      $event.toBeMoved.additionalInformation = this.groupedAvatars.find(
        (g) => g.displayName === $event.newGroup,
      ).references[0].additionalInformation;
      this.groupedAvatars
        .find((g) => g.displayName === $event.newGroup)
        .references.push($event.toBeMoved);
      //remove group if empty
      this.groupedAvatars = this.groupedAvatars.filter(
        (g) => g.references.length > 0,
      );
    }
    this.updateStoreAvatars();
  }

  constructor(public createCollectionStore: CollectionScreenStore) {
    super();
  }

  nameValidation = (name: string): boolean => {
    //don't allow duplicate names
    if (this.groupedAvatars.find((g) => g.displayName === name)) {
      return false;
    }
    return name.length > 0;
  };

  updateStoreAvatars() {
    this.createCollectionStore.setAvatars(this.groupedAvatars);
  }

  onNameChanged(groupName: string, $event: { value: string; valid: boolean }) {
    if (!$event.valid) {
      return;
    }
    this.createCollectionStore.setAvatarActiveGroup($event.value);
    this.groupedAvatars
      .filter((g) => g.displayName === groupName)
      .map((a) => {
        a.displayName = $event.value;
        a.references.map((r) => (r.belongsTo = $event.value));
      });
    this.updateStoreAvatars();
  }

  deleteGroup(group: string) {
    this.groupedAvatars = this.groupedAvatars.filter(
      (g) => g.displayName !== group,
    );
    this.updateStoreAvatars();
  }

  moveGroupUp(group: string) {
    this.createCollectionStore.moveAvatarGroup({ id: group, direction: 'up' });
  }
  moveGroupDown(group: string) {
    this.createCollectionStore.moveAvatarGroup({
      id: group,
      direction: 'down',
    });
  }

  public identifyGroup = (_, item) => {
    return item.key;
  };

  processData(data: AvatarAssetReference[]): void {
    this.groupedAvatars = [];

    data.forEach((d) => {
      if (d.belongsTo) {
        const group = this.groupedAvatars.find(
          (g) => g.displayName === d.belongsTo,
        );
        if (group) {
          if (!group.references.some((avatar) => avatar.id === d.id)) {
            group.references.push(d);
          }
        } else {
          this.groupedAvatars.push({
            displayName: d.belongsTo,
            isValidGroup: true,
            references: [d],
          });
        }
      } else {
        this.groupedAvatars.push({
          displayName: d.name,
          isValidGroup: false,
          references: [d],
        });
      }
    });
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   const amount = event.currentIndex - event.previousIndex;
  //   if (amount > 0) {
  //     // move down
  //     const ref = this.references[event.previousIndex];
  //     for (let i = 0; i < amount; i++) {
  //       this.moveRefDown.emit(ref);
  //     }
  //   }
  //   if (amount < 0) {
  //     // move up
  //     const ref = this.references[event.previousIndex];
  //     for (let i = 0; i < -amount; i++) {
  //       this.moveRefUp.emit(ref);
  //     }
  //   }
  // }
}
