<div class="d-flex flex-row mb-4 assetCard pos-relative">
  <app-row-buttons
    [reference]="reference"
    [disableMove]="disableMoveButtons"
    (moveRowDown)="this.moveReferenceDown.emit(this.reference)"
    (moveRowUp)="this.moveReferenceUp.emit(this.reference)"
    (removeRow)="this.removeReference.emit(this.reference)"
  ></app-row-buttons>
  <div class="collectionThumbnail">
    <img src="{{ reference.thumbnail }}" class="collectionThumbnail" />
  </div>
  <div class="d-flex flex-column ml-3 w-100">
    <app-input-text
      id="ref-name-txt"
      placeholder="Name"
      class="mr-3"
      [value]="reference.name"
      (valueChange)="onNameChanged($event)"
    ></app-input-text>
    <span class="data-label mt-2 ml-1"
      ><b>Reference: </b>
      <span class="data-value">{{ reference.reference }}</span>
      <app-copy-to-clipboard
        data="{{ reference.reference }}"
      ></app-copy-to-clipboard
    ></span>
    <span class="data-label mt-2 ml-1"
      ><b>CS-ID: </b> <span class="data-value">{{ reference.id }}</span>
      <app-copy-to-clipboard data="{{ reference.id }}"></app-copy-to-clipboard
    ></span>
  </div>
  <span cdkDragHandle class="rr-drag dragZone"></span>
</div>
