/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import {
  ComponentStore,
  OnStateInit,
  OnStoreInit,
} from '@ngrx/component-store';
import {
  IReferenceSelectionData,
  getOutfitParallaxInfoUrl,
} from './components/reference-search/reference-selection.component';
import { IRequestService, PRODUCT_TYPE } from '../data';
import {
  CollectionAccessoryReference,
  CollectionAvatarReference,
  CollectionBaseReference,
  CollectionBaseReferencesWithName,
  CollectionGarmentReference,
  CollectionInfo,
  CollectionSceneReference,
  CollectionSettings,
  ICollection,
} from '../collection-overview/collection.interface';

import { v4 as uuid } from 'uuid';
import { Observable, switchMap } from 'rxjs';
import { SelectedFilters } from 'projects/web-ui-component-library/src';
import { CollectionFilters } from './components/reference-search/components/collection-search-filter-bar/collection-search-filter-bar.component';
import { ICentralAssetPlatformService } from '../data/api';
import { AvatarGroup } from './components/avatar-group-list/avatar-group-list.component';
import { DropDownEntry } from '../components/inputs/input-dropdown/input-dropdown.component';

export interface AssetReference extends CollectionBaseReferencesWithName {
  id: string;
  thumbnail?: string;
}

export type AvatarInformation = {
  heightInCm: number;
  displayName: string;
  gender: 'male' | 'female';
};

export type AvatarVariantInformation = {
  shoe: string;
  shoe_color?: string;
};

export type AvatarAssetReference = AssetReference & {
  belongsTo?: string;
  additionalInformation?: AvatarInformation;
  variant?: AvatarVariantInformation;
};

export type ShoeOption = {
  name: string;
  translationKey?: string;
  image?: string;
};

export interface GarmentReference extends CollectionGarmentReference {
  id: string;
  thumbnail?: string;
}

export enum ScreenMode {
  NEW = 'new',
  EDIT = 'edit',
}

export interface OutfitReferenceJson extends CollectionBaseReferencesWithName {
  id: string;
  resultUrl: string;
  thumbnail?: string;
}

export interface OutfitReference {
  id: string;
  scene: CollectionBaseReference;
  avatar: CollectionBaseReference;
  preview: CollectionBaseReference;
  garments: CollectionBaseReference[];
  accessories: CollectionAccessoryReference[];
}

export interface CollectionScreen {
  filteredRequests: IReferenceSelectionData[];
  //data
  id: string;
  info: CollectionInfo;
  settings: CollectionSettings;
  groupedAvatars: AvatarGroup[];
  scenes: AssetReference[];
  garments: GarmentReference[];
  outfits: OutfitReferenceJson[];

  searchProductType: PRODUCT_TYPE;
  searchPattern: string;
  filters: SelectedFilters<CollectionFilters>;
  mode: ScreenMode;
  loading: boolean;
  garmentsLoading: boolean;
  avatarsLoading: boolean;
  scenesLoading: boolean;
  outfitsLoading: boolean;
  avatarActiveGroup: string | undefined;
}

const initState: CollectionScreen = {
  info: {
    name: '',
    imageUrl: '',
  },
  id: '',
  filteredRequests: [],
  groupedAvatars: [],
  scenes: [],
  garments: [],
  outfits: [],
  searchProductType: PRODUCT_TYPE.UNDEFINED,
  searchPattern: '',
  filters: {},
  mode: ScreenMode.EDIT,
  loading: false,
  garmentsLoading: false,
  avatarsLoading: false,
  scenesLoading: false,
  outfitsLoading: false,
  avatarActiveGroup: undefined,
  settings: undefined,
};

@Injectable()
export class CollectionScreenStore
  extends ComponentStore<CollectionScreen>
  implements OnStoreInit, OnStateInit
{
  constructor(
    private _requestService: IRequestService,
    private _capService: ICentralAssetPlatformService,
  ) {
    super(initState);
  }
  ngrxOnStateInit() {}
  ngrxOnStoreInit() {}

  readonly reset = this.updater((_) => {
    return initState;
  });

  readonly setLoading = this.updater((state, loading: boolean) => {
    return {
      ...state,
      loading,
    };
  });

  readonly setGarmentsLoading = this.updater((state, loading: boolean) => {
    return {
      ...state,
      garmentsLoading: loading,
    };
  });

  readonly setAvatarsLoading = this.updater((state, loading: boolean) => {
    return {
      ...state,
      avatarsLoading: loading,
    };
  });

  readonly setScenesLoading = this.updater((state, loading: boolean) => {
    return {
      ...state,
      scenesLoading: loading,
    };
  });

  readonly setOutfitsLoading = this.updater((state, loading: boolean) => {
    return {
      ...state,
      outfitsLoading: loading,
    };
  });

  readonly setMode = this.updater((state, mode: ScreenMode) => {
    return {
      ...state,
      mode,
    };
  });

  readonly setId = this.updater((state, id: string) => {
    return {
      ...state,
      id,
    };
  });

  readonly setAvatarActiveGroup = this.updater(
    (state, activeGroup: string | undefined) => {
      return {
        ...state,
        avatarActiveGroup: activeGroup,
      };
    },
  );

  readonly moveAvatar = this.updater(
    (state, data: { id: string; group: string; direction: 'up' | 'down' }) => {
      let index = undefined;
      const groupAvatars = state.groupedAvatars.find((g, idx) => {
        if (g.displayName === data.group) {
          index = idx;
          return true;
        }
      });
      const avatars =
        data.direction === 'up'
          ? this.moveUp(groupAvatars.references, data.id)
          : this.moveDown(groupAvatars.references, data.id);
      groupAvatars.references = [...avatars];
      if (index !== undefined) {
        state.groupedAvatars[index] = groupAvatars;
        return state;
      }
    },
  );
  readonly moveAvatarGroup = this.updater(
    (state, data: { id: string; direction: 'up' | 'down' }) => {
      const idx = state.groupedAvatars.findIndex(
        (g) => g.displayName === data.id,
      );
      if (idx === -1) {
        return state;
      }
      const element = state.groupedAvatars.splice(idx, 1)[0];
      const newIdx = data.direction === 'up' ? idx - 1 : idx + 1;
      state.groupedAvatars.splice(newIdx, 0, element);
      return state;
    },
  );
  readonly moveScene = this.updater(
    (state, data: { id: string; direction: 'up' | 'down' }) => {
      const scenes =
        data.direction === 'up'
          ? this.moveUp(state.scenes, data.id)
          : this.moveDown(state.scenes, data.id);
      return {
        ...state,
        scenes,
      };
    },
  );
  readonly moveGarment = this.updater(
    (state, data: { id: string; direction: 'up' | 'down' }) => {
      const garments =
        data.direction === 'up'
          ? this.moveUp(state.garments, data.id)
          : this.moveDown(state.garments, data.id);
      return {
        ...state,
        garments,
      };
    },
  );
  readonly moveOutfit = this.updater(
    (state, data: { id: string; direction: 'up' | 'down' }) => {
      const outfits =
        data.direction === 'up'
          ? this.moveUp(state.outfits, data.id)
          : this.moveDown(state.outfits, data.id);
      return {
        ...state,
        outfits: outfits,
      };
    },
  );

  moveUp = (list: any[], id: string, identifier: string = 'id') => {
    const index = list.findIndex((a) => a[identifier] === id);
    if (index > 0) {
      const temp = list[index - 1];
      list[index - 1] = list[index];
      list[index] = temp;
    }

    return list;
  };

  moveDown = (list: any[], id: string, identifier: string = 'id') => {
    const index = list.findIndex((a) => a[identifier] === id);
    if (index < list.length - 1) {
      const temp = list[index + 1];
      list[index + 1] = list[index];
      list[index] = temp;
    }
    return list;
  };

  readonly loadAvatars = this.effect(
    (avatars: Observable<CollectionAvatarReference[]>) => {
      return avatars.pipe(
        switchMap(async (avatars) => {
          this.setAvatarsLoading(true);
          const requests$ = avatars.map(async (avatar) => {
            return this._capService
              .getThumbnail(avatar.organizationId, avatar.reference)
              .then((res) => {
                return {
                  ...avatar,
                  thumbnail: res.thumbnail,
                };
              });
          });
          const t = await Promise.all(requests$);
          const idRequests = t.map((result) =>
            this._capService
              .getId(result.organizationId, result.reference)
              .then((res) => ({
                ...result,
                id: res.id,
              })),
          );

          const resultsWithIds = await Promise.all(idRequests);

          for (const result of resultsWithIds) {
            this.addAvatar(result);
          }
          this.setAvatarsLoading(false);
        }),
      );
    },
  );

  readonly loadScenes = this.effect(
    (scenes: Observable<CollectionSceneReference[]>) => {
      return scenes.pipe(
        switchMap(async (scenes) => {
          this.setScenesLoading(true);
          const requests$ = scenes.map((scene) => {
            return this._capService
              .getThumbnail(scene.organizationId, scene.reference)
              .then((res) => {
                return {
                  ...scene,
                  thumbnail: res.thumbnail,
                };
              });
          });
          const t = await Promise.all(requests$);
          const idRequests = t.map((result) =>
            this._capService
              .getId(result.organizationId, result.reference)
              .then((res) => ({
                ...result,
                id: res.id,
              })),
          );

          const resultsWithIds = await Promise.all(idRequests);

          for (const result of resultsWithIds) {
            this.addOrUpdateScene(result);
          }
          this.setScenesLoading(false);
        }),
      );
    },
  );

  readonly loadGarments = this.effect(
    (garments: Observable<CollectionGarmentReference[]>): Observable<void> => {
      return garments.pipe(
        switchMap(async (garments) => {
          this.setGarmentsLoading(true);
          const requests$ = garments.map((garment) => {
            return this._capService
              .getThumbnail(garment.organizationId, garment.reference)
              .then((res) => {
                if (res.thumbnail == undefined) {
                  console.warn('Thumbnail not found for ' + garment.reference);
                }
                return {
                  ...garment,
                  thumbnail: res.thumbnail,
                };
              });
          });
          const t = await Promise.all(requests$);
          const idRequests = t.map((result) =>
            this._capService
              .getId(result.organizationId, result.reference)
              .then((res) => ({
                ...result,
                id: res.id,
              })),
          );

          const resultsWithIds = await Promise.all(idRequests);

          for (const result of resultsWithIds) {
            this.addOrUpdateGarment(result);
          }
          this.setGarmentsLoading(false);
        }),
      );
    },
  );

  readonly loadOutfits = this.effect(
    (outfits: Observable<OutfitReference[]>) => {
      return outfits.pipe(
        switchMap(async (outfits) => {
          this.setOutfitsLoading(true);
          for (const outfit of outfits) {
            await this._requestService
              .getRequestByID(outfit.id)
              .then((request) => {
                const result: OutfitReferenceJson = {
                  id: request.id,
                  name: request.name,
                  organizationId: request.customerId,
                  reference: `gid://pictofit/sku/${request.sku}`,
                  thumbnail: request.previewUrl,
                  resultUrl:
                    request.productTypeId === PRODUCT_TYPE.OUTFIT_2D_PARALLAX
                      ? getOutfitParallaxInfoUrl(request)
                      : undefined,
                };
                this.addOrUpdateOutfit(result);
              });
          }
          this.setOutfitsLoading(false);
        }),
      );
    },
  );

  readonly setFilters = this.updater(
    (state, filters: SelectedFilters<CollectionFilters>) => ({
      ...state,
      filters: filters,
    }),
  );

  readonly setCollection = async (id: string, collection: ICollection) => {
    this.reset();
    await Promise.all([
      this.setId(id),
      this.setInfoName(collection.info.name),
      this.setInfoImageUrl(collection.info.imageUrl),
      this.setSettings(collection.settings),
      this.loadGarments(collection.garments),
      this.loadAvatars(collection.avatars),
      this.loadScenes(collection.scenes),
      this.loadOutfits(collection.outfits),
    ]);
  };
  //updaters
  readonly setFilteredCollections = this.updater(
    (state, data: IReferenceSelectionData[]) => ({
      ...state,
      filteredRequests: data,
    }),
  );
  readonly setInfoName = this.updater((state, data: string) => ({
    ...state,
    info: { ...state.info, name: data },
  }));
  readonly setInfoImageUrl = this.updater((state, data: string) => ({
    ...state,
    info: { ...state.info, imageUrl: data },
  }));

  readonly setSettings = this.updater((state, data: CollectionSettings) => {
    const shoeOptions = state.settings?.shoeOptions || [];
    const newShoeOptions = data?.shoeOptions || [];
    const deletedShoeOptions = shoeOptions.filter(
      (o) => !newShoeOptions.find((n) => n.name === o.name),
    );
    deletedShoeOptions.forEach((o) => {
      state.groupedAvatars.forEach((avatarGroup) => {
        avatarGroup.references.forEach((avatar, index) => {
          if (avatar.variant?.shoe === o.name) {
            delete avatar.variant.shoe;
            avatarGroup.references[index] = { ...avatar };
          }
        });
      });
    });
    return {
      ...state,
      settings: data,
    };
  });

  readonly updateShoeOptionsName = this.updater(
    (state, data: { name: string; value: string }) => {
      const setting = state.settings?.shoeOptions?.find(
        (o) => o.name === data.name,
      );
      if (setting) {
        //find avatars and update the shoe name
        const oldName = setting.name;
        setting.name = data.value;
        if (data.value !== oldName) {
          state.groupedAvatars.forEach((avatarGroup) => {
            avatarGroup.references.forEach((avatar, index) => {
              if (avatar.variant?.shoe === oldName) {
                avatar.variant.shoe = data.value;
                avatarGroup.references[index] = { ...avatar };
              }
            });
          });
        }
      }
      return { ...state };
    },
  );

  readonly updateShoeOptionsTranslation = this.updater(
    (state, data: { name: string; value: string }) => {
      const setting = state.settings?.shoeOptions?.find(
        (o) => o.name === data.name,
      );
      if (setting) {
        setting.translationKey = data.value;
      }
      return { ...state };
    },
  );

  readonly updateShoeOptionsImage = this.updater(
    (state, data: { name: string; value: string }) => {
      const setting = state.settings?.shoeOptions?.find(
        (o) => o.name === data.name,
      );
      if (setting) {
        setting.image = data.value;
      }
      return { ...state };
    },
  );

  readonly addGroupAvatars = this.updater((state, data: AvatarGroup) => {
    if (data.isValidGroup) {
      const group = state.groupedAvatars.find(
        (group) => group.displayName === data.displayName,
      );
      if (group) {
        if (
          group.references.some((avatar) => avatar.id === data.references[0].id)
        )
          return state;
        group.references.push(data.references[0]);
        return {
          ...state,
        };
      }
    }
  });
  //add avatar initially
  readonly addAvatar = this.updater((state, data: AvatarAssetReference) => {
    //add it to the current opened group
    if (state.avatarActiveGroup !== undefined) {
      data.belongsTo = state.avatarActiveGroup;
      //take additonal information from first group avatar reference
      data.additionalInformation = state.groupedAvatars.find(
        (g) => g.displayName === state.avatarActiveGroup,
      )?.references[0]?.additionalInformation;
    } else {
      if (!data.additionalInformation) {
        data.additionalInformation = {
          heightInCm: 160,
          displayName: 'init',
          gender: 'female',
        };
      }
    }
    //only add avatar if not added anywhere else
    if (
      state.groupedAvatars.some((g) =>
        g.references.some((r) => r.id === data.id),
      )
    ) {
      return state;
    }
    if (data.belongsTo === undefined) {
      //no group assigned, so we add it as standalone avatar if not already in list
      return {
        ...state,
        groupedAvatars: [
          ...state.groupedAvatars,
          { isValidGroup: false, displayName: data.name, references: [data] },
        ],
      };
    } else {
      //was already in a group, so we have to reasign it.
      const targetGroup = state.groupedAvatars.find(
        (group) => group.displayName === data.belongsTo,
      );
      const groupAvatars = targetGroup?.references;
      if (groupAvatars) {
        groupAvatars.push(data);
        return {
          ...state,
          groupedAvatars: [...state.groupedAvatars],
        };
      } else {
        //new group
        const newGroup: AvatarGroup = {
          displayName: data.belongsTo,
          references: [data],
          isValidGroup: true,
        };
        return {
          ...state,
          groupedAvatars: [...state.groupedAvatars, newGroup],
        };
      }
    }
  });

  readonly updateAvatarData = this.updater(
    (state, data: { ref: AvatarAssetReference; group: string }) => {
      let index = -1;
      const groupAvatars = state.groupedAvatars.find((g, idx) => {
        index = idx;
        return g.displayName === data.group;
      }).references;
      const avatarIndex = groupAvatars.findIndex(
        (avatar) => avatar.id === data.ref.id,
      );
      groupAvatars[avatarIndex] = data.ref;

      state.groupedAvatars[index].references = groupAvatars;

      return { ...state };
    },
  );

  readonly setAvatars = this.updater((state, data: AvatarGroup[]) => {
    return {
      ...state,
      groupedAvatars: data,
    };
  });

  readonly updateThumbnail = this.updater(
    (state, data: { id: uuid; thumbnail: string }) => {
      return {
        ...state,
        groupedAvatars: state.groupedAvatars.map((x) => {
          for (let i = 0; i < x.references.length; i++) {
            if (x.references[i].id === data.id) {
              x.references[i].thumbnail = data.thumbnail;
            }
          }
          return x;
        }),
      };
    },
  );

  readonly removeAvatar = this.updater((state, data: AvatarAssetReference) => {
    let avatars = state.groupedAvatars.map((g) => {
      g.references = g.references.filter((x) => x.id !== data.id);
      return g;
    });
    avatars = avatars.filter((a) => a.references.length > 0);
    return {
      ...state,
      groupedAvatars: avatars,
    };
  });

  readonly removeAvatarGroup = this.updater((state, group: string) => {
    const filtered = state.groupedAvatars.filter(
      (x) => x.displayName !== group,
    );
    if (state.avatarActiveGroup === group) {
      this.setAvatarActiveGroup(undefined);
    }
    return {
      ...state,
      groupedAvatars: filtered,
    };
  });

  readonly addOrUpdateScene = this.updater((state, data: AssetReference) => {
    const index = state.scenes.findIndex((x) => x.id === data.id);
    //add new, if not found
    if (index === -1) {
      return {
        ...state,
        scenes: [...state.scenes, data],
      };
    }
    //update existing
    state.scenes[index] = data;
    return {
      ...state,
      scenes: [...state.scenes],
    };
  });
  readonly removeScene = this.updater((state, data: AssetReference) => {
    const scenes = state.scenes.filter((x) => x.id !== data.id);
    return {
      ...state,
      scenes: scenes,
    };
  });

  readonly addOrUpdateGarment = this.updater((state, data: AssetReference) => {
    const index = state.garments.findIndex((x) => x.id === data.id);
    //add new, if not found
    if (index === -1) {
      return {
        ...state,
        garments: [...state.garments, data],
      };
    }
    //update existing
    state.garments[index] = data;
    return {
      ...state,
      garments: [...state.garments],
    };
  });
  readonly removeGarment = this.updater((state, data: AssetReference) => {
    const garments = state.garments.filter((x) => x.id !== data.id);
    return {
      ...state,
      garments: garments,
    };
  });

  readonly addOrUpdateOutfit = this.updater(
    (state, data: OutfitReferenceJson) => {
      const index = state.outfits.findIndex((x) => x.id === data.id);
      //add new, if not found
      if (index === -1) {
        return {
          ...state,
          outfits: [...state.outfits, data],
        };
      }
      //update existing
      state.outfits[index] = data;
      return {
        ...state,
        outfits: [...state.outfits],
      };
    },
  );

  readonly removeOutfit = this.updater((state, data: OutfitReferenceJson) => {
    const outfits = state.outfits.filter((x) => x.id !== data.id);
    return {
      ...state,
      outfits: outfits,
    };
  });

  readonly setSearchProductType = this.updater((state, data: PRODUCT_TYPE) => ({
    ...state,
    searchProductType: data,
    searchPattern: '',
  }));

  //selects
  readonly info$ = this.select((state) => state.info);
  readonly settings$ = this.select((state) => state.settings);
  readonly infoImage$ = this.select((state) => state.info.imageUrl);
  readonly infoName$ = this.select((state) => state.info.name);
  readonly filteredRequests$ = this.select((state) => state.filteredRequests);
  readonly avatars$ = this.select((state) => state.groupedAvatars);
  readonly avatarById$ = this.select(
    (state) => (id: string) =>
      state.groupedAvatars.map((x) => x.references.find((y) => y.id === id)),
  );
  readonly scenes$ = this.select((state) => state.scenes);
  readonly garments$ = this.select((state) => state.garments);
  readonly outfits$ = this.select((state) => state.outfits);
  readonly searchProductType$ = this.select((state) => state.searchProductType);

  readonly avatarsVisible$ = this.select(
    (state) => state.searchProductType === PRODUCT_TYPE.AVATAR_2D,
  );
  readonly scenesVisible$ = this.select(
    (state) => state.searchProductType === PRODUCT_TYPE.SCENE,
  );
  readonly garmentsVisible$ = this.select(
    (state) => state.searchProductType === PRODUCT_TYPE.GARMENT_2D,
  );
  readonly outfitsVisible$ = this.select(
    (state) => state.searchProductType === PRODUCT_TYPE.OUTFIT_2D_PARALLAX,
  );
  readonly searchPattern$ = this.select((state) => state.searchPattern);
  readonly filters$ = this.select((state) => state.filters);
  readonly screenMode$ = this.select((state) => state.mode);
  readonly id$ = this.select((state) => state.id);
  readonly loading$ = this.select((state) => {
    return (
      state.garmentsLoading ||
      state.scenesLoading ||
      state.avatarsLoading ||
      state.outfitsLoading
    );
  });
  readonly garmentsLoading$ = this.select((state) => state.garmentsLoading);
  readonly scenesLoading$ = this.select((state) => state.scenesLoading);
  readonly avatarsLoading$ = this.select((state) => state.avatarsLoading);
  readonly outfitsLoading$ = this.select((state) => state.outfitsLoading);
  readonly avatarActiveGroup$ = this.select((state) => state.avatarActiveGroup);
  readonly groupEntries$ = this.select((state) => {
    const groups: DropDownEntry[] = [];
    state.groupedAvatars.forEach((g) => {
      if (g.isValidGroup && !groups.find((x) => x.id === g.displayName)) {
        groups.push({
          id: g.displayName,
          data: g.displayName,
        });
      }
    });
    groups.push({ id: '+', data: '!new group!' });
    return groups;
  });
  readonly shoeVariants$ = this.select((state) =>
    state.settings?.shoeOptions.map((o) => ({ id: o.name, data: o.name })),
  );
}
