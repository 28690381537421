import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { InputTextComponent } from '../../../components/inputs/input-text/input-text.component';
import { CommonModule } from '@angular/common';
import { BaseListRowComponent } from '../base-list-row/base-list-row.component';
import {
  AvatarAssetReference,
  CollectionScreenStore,
} from '../../collection-screen.state';
import { RowButtonsComponent } from '../row-buttons/row-buttons.component';

import { CopyToClipboardComponent } from '../copy-to-clipboard/copy-to-clipboard.component';
import { AvatarGroup } from '../avatar-group-list/avatar-group-list.component';
import { InputDropdownComponent } from '../../../components/inputs/input-dropdown/input-dropdown.component';
import { CollectionSettings } from '../../../collection-overview/collection.interface';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-avatar-list-row',
  standalone: true,
  imports: [
    InputTextComponent,
    CommonModule,
    RowButtonsComponent,
    CopyToClipboardComponent,
    InputDropdownComponent,
  ],
  templateUrl: './avatar-list-row.component.html',
  styleUrls: [
    '../base-list-row/base-list-row.scss',
    './avatar-list-row.component.scss',
  ],
})
export class AvatarListRowComponent
  extends BaseListRowComponent<AvatarAssetReference>
  implements OnInit, OnDestroy
{
  @Input() groups: AvatarGroup[];
  @Output() groupChanged: EventEmitter<{
    newGroup: string;
    toBeMoved: AvatarAssetReference;
  }> = new EventEmitter();
  @Output() moveUpGroup: EventEmitter<AvatarAssetReference> =
    new EventEmitter();
  @Output() moveDownGroup: EventEmitter<AvatarAssetReference> =
    new EventEmitter();

  dropdownValueGroup: string = '';
  settings: CollectionSettings = undefined;
  _onDestroy$: Subject<void>;

  constructor(public createCollectionStore: CollectionScreenStore) {
    super();
  }
  ngOnDestroy(): void {
    if (this._onDestroy$) {
      this._onDestroy$.next();
      this._onDestroy$.complete();
      this._onDestroy$.unsubscribe();
      this._onDestroy$ = undefined;
    }
  }

  ngOnInit(): void {
    this._onDestroy$ = new Subject<void>();
    this.createCollectionStore.settings$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((data) => (this.settings = data));
  }

  onNameChanged($event: { value: string; valid: boolean }) {
    if ($event.valid) {
      this.updateReference.emit({ ...this.reference, name: $event.value });
    }
  }

  onShoeVariantChanged(shoeName: string, avatar: AvatarAssetReference) {
    if (avatar.variant) {
      avatar.variant.shoe = shoeName;
    } else {
      avatar.variant = { shoe: shoeName };
    }
    this.createCollectionStore.updateAvatarData({
      ref: avatar,
      group: avatar.belongsTo,
    });
  }

  onGroupChanged(newGroup: string, toBeMoved: AvatarAssetReference) {
    this.groupChanged.emit({ newGroup, toBeMoved });
    this.dropdownValueGroup = '';
  }
}
