<div class="header {{ visible ? 'mb-3' : '' }}">
  <h2>
    {{ title }}
  </h2>
  <span
    title="loading..."
    class="loader"
    [hidden]="(this.createCollectionStore.avatarsLoading$ | async) === false"
  ></span>
  <button
    class="btn btn-outline-primary"
    (click)="this.visible = !this.visible"
    [hidden]="this.createCollectionStore.avatarsLoading$ | async"
  >
    {{ visible ? 'Close' : 'Edit' }}
  </button>
</div>
<h3 *ngIf="visible">Shoe Options</h3>
<div
  [ngClass]="visible ? 'd-flex flex-column gap-2' : 'd-none'"
  *ngFor="
    let shoeOption of (this.createCollectionStore.settings$ | async)
      ?.shoeOptions
  "
>
  <div class="d-flex flex-row gap-2 w-10 align-items-center mb-1">
    <app-input-text
      [value]="shoeOption.name"
      (valueChange)="onChanged($event, 'name', shoeOption)"
      placeholder="Name"
      class="col-3"
      [validationFunction]="validate"
    ></app-input-text>
    <app-input-text
      [value]="shoeOption.translationKey"
      (valueChange)="onChanged($event, 'translationKey', shoeOption)"
      placeholder="Translation Key"
      class="col-3"
    ></app-input-text>
    <app-input-text
      [value]="shoeOption.image"
      (valueChange)="onChanged($event, 'image', shoeOption)"
      placeholder="Image"
      class="col-5"
    ></app-input-text>
    <span
      class="col-1 rr-trash clickable"
      (click)="onDelete(shoeOption.name)"
    ></span>
  </div>
</div>
<div
  [ngClass]="
    visible ? 'd-flex flex-column gap-2 mt-1 align-items-center' : 'd-none'
  "
>
  <button
    class="btn btn-outline-primary w-25"
    (click)="this.addNewShoeType()"
    [hidden]="this.createCollectionStore.avatarsLoading$ | async"
  >
    Add Shoe type
  </button>
</div>
