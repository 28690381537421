import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputTextComponent } from 'projects/content-service-cms/src/app/components/inputs/input-text/input-text.component';
import {
  CollectionScreenStore,
  ShoeOption,
} from '../../collection-screen.state';
import { Subject, takeUntil } from 'rxjs';
import { CollectionSettings } from '../../../collection-overview/collection.interface';

@Component({
  selector: 'app-collection-settings',
  standalone: true,
  imports: [InputTextComponent, CommonModule],
  templateUrl: './collection-settings.component.html',
  styleUrls: [
    './collection-settings.component.scss',
    '../base-list/base-list.scss',
  ],
})
export class CollectionSettingsComponent implements OnInit, OnDestroy {
  constructor(public createCollectionStore: CollectionScreenStore) {}

  public visible = false;
  public title = 'Settings';
  private _onDestroy$: Subject<void>;
  private _settings: CollectionSettings;

  ngOnInit(): void {
    this._onDestroy$ = new Subject<void>();
    this.createCollectionStore.settings$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((settings) => {
        if (settings) {
          this._settings = { ...settings };
        }
      });
  }

  ngOnDestroy(): void {
    if (this._onDestroy$) {
      this._onDestroy$.next();
      this._onDestroy$.complete();
      this._onDestroy$.unsubscribe();
      this._onDestroy$ = undefined;
    }
  }

  validate = (value: string) => {
    if (value === undefined || value.length === 0) {
      return false;
    }
    return !this._settings?.shoeOptions?.some((o) => o.name === value);
  };

  onChanged(
    $event: { value: string; valid: boolean },
    id: string,
    shoeOption: ShoeOption,
  ) {
    if (!$event.valid) return;
    if (id === 'name') {
      this.createCollectionStore.updateShoeOptionsName({
        name: shoeOption.name,
        value: $event.value,
      });
    }
    if (id === 'translationKey') {
      this.createCollectionStore.updateShoeOptionsTranslation({
        name: shoeOption.name,
        value: $event.value,
      });
    }
    if (id === 'image') {
      this.createCollectionStore.updateShoeOptionsImage({
        name: shoeOption.name,
        value: $event.value,
      });
    }
  }

  addNewShoeType = () => {
    if (this._settings === undefined) this._settings = { shoeOptions: [] };
    this._settings?.shoeOptions.push({
      image: 'imageUrl',
      name: 'set name',
      translationKey: 'translaton key',
    });
    this.createCollectionStore.setSettings(this._settings);
  };

  onDelete(optionName: string) {
    if (
      confirm(
        'Deleting this type will also delete the selected group from the affected avatars.',
      )
    ) {
      this._settings.shoeOptions = this._settings.shoeOptions.filter(
        (option) => option.name !== optionName,
      );
      this.createCollectionStore.setSettings(this._settings);
    }
  }
}
