<span
  class="rr-moveUp clickable"
  (click)="moveRowUp.emit(reference)"
  [class.disabled]="disableMove === 'up' || disableMove === 'both'"
  [ngClass]="{ 'grayed-out': disableMove === 'up' || disableMove === 'both' }"
></span>
<span class="rr-trash clickable" (click)="removeRow.emit(reference)"></span>
<span
  class="rr-moveDown clickable"
  (click)="moveRowDown.emit(reference)"
  [class.disabled]="disableMove === 'down' || disableMove === 'both'"
  [ngClass]="{ 'grayed-out': disableMove === 'down' || disableMove === 'both' }"
></span>
