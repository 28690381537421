<div class="d-flex flex-row mb-4 assetCard pos-relative">
  <app-row-buttons
    [reference]="reference"
    [disableMove]="disableMoveButtons"
    (moveRowDown)="
      reference.belongsTo
        ? this.moveReferenceDown.emit(this.reference)
        : this.moveDownGroup.emit(this.reference)
    "
    (moveRowUp)="
      reference.belongsTo
        ? this.moveReferenceUp.emit(this.reference)
        : this.moveUpGroup.emit(this.reference)
    "
    (removeRow)="this.removeReference.emit(this.reference)"
  ></app-row-buttons>
  <div class="collectionThumbnail">
    <img src="{{ reference.thumbnail }}" class="collectionThumbnail" />
  </div>
  <div class="d-flex flex-column ml-3 w-100 justify-content-between">
    <div class="d-flex flex-column ml-3 w-100">
      <app-input-text
        id="ref-name-txt"
        placeholder="Name"
        class="mr-3 name"
        [value]="reference.name"
        (valueChange)="onNameChanged($event)"
      ></app-input-text>
      <span class="data-label mt-2 ml-1"
        ><b>Reference: </b>
        <span class="data-value">{{ reference.reference }}</span>
        <app-copy-to-clipboard
          data="{{ reference.reference }}"
        ></app-copy-to-clipboard
      ></span>
      <span class="data-label mt-2 ml-1"
        ><b>CS-ID: </b> <span class="data-value">{{ reference.id }}</span>
        <app-copy-to-clipboard data="{{ reference.id }}"></app-copy-to-clipboard
      ></span>
    </div>
    <div
      class="d-flex flex-row flex-end flex-wrap-reverse ml-3 w-30 justify-content-between mb-1"
    >
      <app-input-dropdown
        class="dropdown mr-5"
        [entries]="this.createCollectionStore.shoeVariants$ | async"
        placeholder="Shoe Type"
        [value]="reference.variant?.shoe"
        (valueChange)="onShoeVariantChanged($event.id.toString(), reference)"
        [disabled]="false"
      ></app-input-dropdown>
      <app-input-dropdown
        class="dropdown"
        [entries]="this.createCollectionStore.groupEntries$ | async"
        placeholder="Move to Group"
        [value]="dropdownValueGroup"
        (valueChange)="onGroupChanged($event.id.toString(), reference)"
        [disabled]="false"
      ></app-input-dropdown>

      <!-- <span cdkDragHandle class="rr-drag dragZone"></span> -->
    </div>
  </div>
</div>
