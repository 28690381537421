import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-reference-list',
  standalone: true,
  imports: [CommonModule, DragDropModule],
  template: 'test',
})
export abstract class BaseListComponent<T> implements OnInit, OnDestroy {
  @Input() data$: Observable<T[]>;
  @Input() loading$: Observable<boolean>;
  @Output() addOrUpdateReference: EventEmitter<T> = new EventEmitter();
  @Output() removeRef: EventEmitter<T> = new EventEmitter();
  @Output() updateVisibility: EventEmitter<boolean> = new EventEmitter();
  @Output() moveRefUp: EventEmitter<T> = new EventEmitter();
  @Output() moveRefDown: EventEmitter<T> = new EventEmitter();
  public references: T[] = [];
  @Input() visible = false;

  private subscription = undefined;

  constructor() {}
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.subscription = this.data$.subscribe((data) => {
      if (data) {
        this.references = data;
      }
    });
  }
  updateReference(reference: T) {
    this.addOrUpdateReference.emit(reference);
  }
  removeReference(reference: T) {
    this.removeRef.emit(reference);
  }

  openClicked() {
    this.visible = !this.visible;
    this.updateVisibility.emit(this.visible);
  }

  public identify = (_, item) => {
    return item.id;
  };

  drop(event: CdkDragDrop<string[]>) {
    const amount = event.currentIndex - event.previousIndex;
    if (amount > 0) {
      // move down
      const ref = this.references[event.previousIndex];
      for (let i = 0; i < amount; i++) {
        this.moveRefDown.emit(ref);
      }
    }
    if (amount < 0) {
      // move up
      const ref = this.references[event.previousIndex];
      for (let i = 0; i < -amount; i++) {
        this.moveRefUp.emit(ref);
      }
    }
  }

  @Input() title: string;
}
