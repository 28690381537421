<div class="header {{ visible ? 'mb-3' : '' }}">
  <h2>
    {{ title }}
    <span style="font-size: small">
      ({{ this.avatarCount }} in {{ this.groupedAvatars.length }} groups)</span
    >
  </h2>
  <span
    title="loading..."
    class="loader"
    [hidden]="(loading$ | async) === false"
  ></span>
  <button
    class="btn btn-outline-primary"
    (click)="openClicked()"
    [hidden]="loading$ | async"
  >
    {{ visible ? 'Close' : 'Edit' }}
  </button>
</div>
<div [hidden]="!visible">
  <span *ngIf="this.avatarCount === 0"><b>No items selected...</b></span>
  <div
    *ngFor="let g of groupedAvatars; trackBy: identifyGroup"
    cdkDropList
    class="groupFrame"
    (cdkDropListDropped)="drop($event)"
    [cdkDropListAutoScrollStep]="20"
    [cdkDropListAutoScrollDisabled]="false"
  >
    <div>
      <div *ngIf="g.isValidGroup" class="d-flex flex-row mb-3">
        <app-input-text
          id="group-name"
          placeholder="Group Name"
          class="mr-3 group-name"
          [value]="g.isValidGroup ? g.displayName : ''"
          (valueChange)="onNameChanged(g.displayName, $event)"
          [validationFunction]="nameValidation"
        ></app-input-text>
        <app-input-text
          id="height"
          placeholder="Height(cm)"
          class="mr-3 group-height"
          [value]="
            g.isValidGroup
              ? g.references[0].additionalInformation?.heightInCm.toString()
              : '0'
          "
          (valueChange)="onGroupHeightChanged(g.displayName, $event)"
          [validationFunction]="heightValidation"
        ></app-input-text>
        <app-input-dropdown
          class="group-gender"
          [entries]="[
            { id: 'female', data: 'female' },
            { id: 'male', data: 'male' }
          ]"
          placeholder="Gender"
          [value]="g.references[0].additionalInformation?.gender"
          (valueChange)="
            $event.data === 'female' || $event.data === 'male'
              ? onGroupGenderChanged(g.displayName, $event.data)
              : ''
          "
          [disabled]="false"
        ></app-input-dropdown>
        <div class="d-flex flex-row align-items-center buttons">
          <span
            class="rr-moveUp clickable"
            (click)="moveGroupUp(g.displayName)"
            [class.disabled]="disableMove === 'up' || disableMove === 'both'"
            [ngClass]="{
              'grayed-out': disableMove === 'up' || disableMove === 'both'
            }"
          ></span>
          <span
            class="rr-moveDown clickable"
            (click)="moveGroupDown(g.displayName)"
            [class.disabled]="disableMove === 'down' || disableMove === 'both'"
            [ngClass]="{
              'grayed-out': disableMove === 'down' || disableMove === 'both'
            }"
          ></span>
          <span
            class="rr-edit clickable"
            (click)="
              activeGroup === undefined || activeGroup !== g.displayName
                ? this.createCollectionStore.setAvatarActiveGroup(g.displayName)
                : this.createCollectionStore.setAvatarActiveGroup(undefined)
            "
          ></span>
          <span
            class="rr-trash clickable"
            (click)="onDelete(g.displayName)"
          ></span>
        </div>
        <span class="counter"
          >{{ g.references.length }}
          {{ g.references.length === 1 ? 'Avatar' : 'Avatars' }}
        </span>
      </div>
      <div
        [hidden]="activeGroup !== g.displayName && g.isValidGroup"
        *ngFor="let avatar of g.references; index as i; trackBy: identify"
        cdkDrag
      >
        <app-avatar-list-row
          [reference]="avatar"
          [title]="g.displayName"
          [groups]="groupedAvatars"
          (updateReference)="
            this.createCollectionStore.updateAvatarData({
              ref: $event,
              group: g.displayName
            })
          "
          (groupChanged)="this.onGroupChanged(g.displayName, $event)"
          (moveUpGroup)="this.moveGroupUp($event.name)"
          (moveDownGroup)="this.moveGroupDown($event.name)"
          (removeReference)="this.createCollectionStore.removeAvatar($event)"
          (moveReferenceUp)="
            this.createCollectionStore.moveAvatar({
              id: $event.id,
              group: $event.belongsTo,
              direction: 'up'
            })
          "
          (moveReferenceDown)="
            this.createCollectionStore.moveAvatar({
              id: $event.id,
              group: $event.belongsTo,
              direction: 'down'
            })
          "
          [disableMoveButtons]="
            !g.isValidGroup
              ? undefined
              : i === 0
              ? g.references.length === 1
                ? 'both'
                : 'up'
              : i === g.references.length - 1
              ? 'down'
              : undefined
          "
        >
        </app-avatar-list-row>
      </div>
    </div>
  </div>
</div>
