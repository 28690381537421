import { Component, Input } from '@angular/core';
import { IReferenceSelectionData } from '../../reference-selection.component';
import { CollectionScreenStore } from '../../../../collection-screen.state';
import { PRODUCT_TYPE } from 'projects/content-service-cms/src/app/data';
import { CommonModule } from '@angular/common';
import { RRCopyToClipboardModule } from 'projects/web-ui-component-library/src';
import { CopyToClipboardComponent } from '../../../copy-to-clipboard/copy-to-clipboard.component';

@Component({
  selector: 'app-reference-entry',
  standalone: true,
  imports: [CommonModule, RRCopyToClipboardModule, CopyToClipboardComponent],
  templateUrl: './reference-entry.component.html',
  styleUrl: './reference-entry.component.scss',
})
export class ReferenceEntryComponent {
  constructor(private _createCollectionStore: CollectionScreenStore) {}
  @Input()
  public reference: IReferenceSelectionData;

  onSelect() {
    if (!this.reference.synced) {
      return;
    }
    switch (this.reference.type) {
      case PRODUCT_TYPE.AVATAR_2D:
        this._createCollectionStore.addAvatar({
          id: this.reference.id,
          thumbnail: this.reference.thumbnail,
          name: this.reference.name,
          reference: this.reference.reference,
          organizationId: this.reference.organizationId,
        });
        break;
      case PRODUCT_TYPE.SCENE:
        this._createCollectionStore.addOrUpdateScene({
          id: this.reference.id,
          name: this.reference.name,
          thumbnail: this.reference.thumbnail,
          reference: this.reference.reference,
          organizationId: this.reference.organizationId,
        });
        break;
      case PRODUCT_TYPE.GARMENT_2D:
        this._createCollectionStore.addOrUpdateGarment({
          id: this.reference.id,
          thumbnail: this.reference.thumbnail,
          name: this.reference.name,
          reference: this.reference.reference,
          organizationId: this.reference.organizationId,
        });
        break;
      case PRODUCT_TYPE.OUTFIT_2D_PARALLAX:
        this._createCollectionStore.addOrUpdateOutfit({
          id: this.reference.id,
          thumbnail: this.reference.thumbnail,
          resultUrl: this.reference.additionalDataUrl,
          reference: this.reference.reference,
          organizationId: this.reference.organizationId,
          name: this.reference.name,
        });
        break;
    }
  }
}
