import { Component, Input } from '@angular/core';
import { TableRowComponent } from 'projects/web-ui-component-library/src';
import { CollectionRecord } from '../collection-list.page.component';
import { Subject, firstValueFrom } from 'rxjs';
import {
  CollectionScreenStore,
  ScreenMode,
} from 'projects/content-service-cms/src/app/collection-screen/collection-screen.state';
import {
  ICentralAssetPlatformService,
  IRequestService,
} from 'projects/content-service-cms/src/app/data/api';
import { ICustomerService } from 'projects/content-service-cms/src/app/customer';
import { Router } from '@angular/router';
import { PreviewService } from '../../../services/collection-preview.service';
import { AlertService } from 'projects/content-service-cms/src/app/core/services/alert.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tbody.app-collection-list-row',
  standalone: true,
  imports: [],
  templateUrl: './collection-list-row.component.html',
  styleUrl: './collection-list-row.component.scss',
})
export class CollectionListRowComponent
  implements TableRowComponent<CollectionRecord>
{
  notImplemnted($event) {
    $event.stopPropagation();
    this._alertService.showErrorAlert('Not Implemented yet');
  }

  @Input() record: CollectionRecord;
  @Input() loaded: Subject<void>;

  async editCollection(collection: CollectionRecord) {
    const currentCustomer = (
      await firstValueFrom(this._customerService.currentCustomer$)
    ).id;
    //this._collectionScreenStore.setLoading(true);
    const collectionData = (
      await this._capService.getAllCollections(currentCustomer)
    ).filter((c) => c.externalReferences.includes(collection.references[0]))[0];

    if (collectionData) {
      for (const a of collectionData.collection.avatars) {
        if (a.id === undefined) {
          const result = await this._capService.getId(
            a.organizationId,
            a.reference,
          );
          a.id = result.id;
        }
      }
      for (const a of collectionData.collection.scenes) {
        if (a.id === undefined) {
          const result = await this._capService.getId(
            a.organizationId,
            a.reference,
          );
          a.id = result.id;
        }
      }
      for (const a of collectionData.collection.garments) {
        if (a.id === undefined) {
          const result = await this._capService.getId(
            a.organizationId,
            a.reference,
          );
          a.id = result.id;
        }
      }
      await this._store.setCollection(
        collectionData.id,
        collectionData.collection,
      );
      this._collectionScreenStore.setMode(ScreenMode.EDIT);
      this._router.navigate(['collections', collection.id]);
    }
  }

  async onPreviewCollection($event: Event, collection: CollectionRecord) {
    $event.stopPropagation();
    const currentCustomer = (
      await firstValueFrom(this._customerService.currentCustomer$)
    ).id;
    // const collectionData = await this._capService.getCollection(
    //   currentCustomer,
    //   collection.references[0],
    // );
    const collectionData = (
      await this._capService.getAllCollections(currentCustomer)
    ).filter((c) => c.externalReferences.includes(collection.references[0]))[0];

    this._previewService.show(
      currentCustomer,
      collection.references[0],
      collectionData,
    );
  }

  constructor(
    private _store: CollectionScreenStore,
    private _capService: ICentralAssetPlatformService,
    private _customerService: ICustomerService,
    private _router: Router,
    private _collectionScreenStore: CollectionScreenStore,
    private _previewService: PreviewService,
    private _alertService: AlertService,
    private _requestService: IRequestService,
  ) {}
}
